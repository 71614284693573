<template>
  <section class="pt-4 pb-3" id="video">
    <h4 class="mb-4">Tutoriaux vidéo</h4>
    <b-row>
      <b-col
        v-for="i in 8"
        :key="i"
        lg="3"
        md="6"
        class="d-flex flex-column mb-3"
      >
        <h-card p0>
          <div
            class="video-thumbnail cursor-pointer d-flex align-items-center justify-content-center"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/img/control-page.png') + ')',
            }"
          >
            <b-icon icon="play-circle" scale="3"></b-icon>
          </div>
          <h6 class="px-3">
            Lorem ipsum dolor sit amet consectetur
          </h6>
        </h-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.video-thumbnail {
  height: 150px;
  width: 100%;
  background-size: cover;
  border: 1px solid #e9e9f0;
  border-radius: 0.25em 0.25em 0 0;
  transition: 0.3s;
  svg {
    transition: 0.3s;
  }
  &:hover {
    filter: brightness(90%);
    svg {
      transform: scale(1.2);
    }
  }
}
h6 {
  font-weight: 600;
}
</style>
