import client from './axios.js'

export default {
  getTicketList(params) {
    return client.post('/issue/list', params)
  },
  getTicketDetail(idIssue) {
    return client.get(`/issue/${idIssue}`)
  },
}
