<template>
  <section class="pt-4 pb-3" id="guides">
    <h4 class="mb-4">Guides d'utilisation</h4>
    <b-row>
      <b-col v-for="i in 7" :key="i" md="6" class="d-flex flex-column mb-3 ">
        <h-card class="cursor-pointer" hover-grey>
          <b-row>
            <b-col
              cols="2"
              sm="3"
              md="2"
              class="d-flex align-items-center justify-content-center"
            >
              <img
                :src="require('@/assets/svg/document.svg')"
                alt="document"
                class="w-100"
              />
            </b-col>
            <b-col>
              <h5 class="my-0 text-primary">
                Lorem ipsum dolor sit amet consectetur
              </h5>
              <span class="mt-2 d-block text-guide"
                >Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Explicabo nobis quo id facere! Nihil quos
              </span></b-col
            >
          </b-row>
        </h-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.text-guide {
  color: $h-main-text-light;
}
</style>
