<template>
  <b-container>
    <HPageTitle>
      Aide & support
    </HPageTitle>
    <b-row>
      <b-col lg="4" class="mb-3 mb-lg-0">
        <HelpCard link-to="#guides">
          <template v-slot:illustration>
            <img
              :src="require('@/assets/svg/illu-guide.svg')"
              alt="guide"
              class="w-100 illustration"
            />
          </template>
          <template v-slot:title-help>
            Guides d'utilisation
          </template>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit.
        </HelpCard>
      </b-col>
      <b-col lg="4" class="mb-3 mb-lg-0">
        <HelpCard link-to="#video">
          <template v-slot:illustration>
            <img
              :src="require('@/assets/svg/illu-video-tutorials.svg')"
              class="w-100 illustration"
              alt="tutoriaux"
            />
          </template>
          <template v-slot:title-help>
            Tutoriaux vidéo
          </template>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit.
        </HelpCard>
      </b-col>
      <b-col lg="4" class="mb-3 mb-lg-0">
        <HelpCard link-to="#assistance">
          <template v-slot:illustration>
            <img
              :src="require('@/assets/svg/illu-support2.svg')"
              class="w-100 illustration"
              alt="assistance technique"
            />
          </template>
          <template v-slot:title-help>
            Tickets support
          </template>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit.
        </HelpCard>
      </b-col>
    </b-row>
    <HelpUserGuide />
    <HelpVideoTuto />
    <HelpAssistance />
  </b-container>
</template>

<script>
import HelpCard from '@/components/help/HelpCard.vue'
import HelpUserGuide from '@/components/help/HelpUserGuide.vue'
import HelpVideoTuto from '@/components/help/HelpVideoTuto.vue'
import HelpAssistance from '@/components/help/HelpAssistance.vue'

export default {
  components: {
    HelpCard,
    HelpUserGuide,
    HelpVideoTuto,
    HelpAssistance,
  },
}
</script>

<style lang="scss" scoped>
.illustration {
  max-width: 100px;
}
</style>
