<template>
  <div class="accordion-header cursor-pointer" role="tablist">
    <b-row
      header-tag="header"
      role="tab"
      class="px-3 py-3 d-flex align-items-center no-wrap"
      v-b-toggle="`accordion-${index}`"
    >
      <b-col cols="auto" class="text-secondarytext">{{
        formatDateFr(ticket.creationDate)
      }}</b-col>
      <b-col class="font-weight-bold">{{ ticket.title }}</b-col>
      <b-col
        cols="1"
        md="2"
        :class="statusTicketMap(ticket.idStatus).color"
        class="font-weight-bold"
      >
        <b-icon
          class="mr-1"
          :class="statusTicketMap(ticket.idStatus).color"
          :icon="statusTicketMap(ticket.idStatus).icon"
        ></b-icon>
        <span class="d-none d-md-inline">
          {{ statusTicketMap(ticket.idStatus).label }}</span
        >
      </b-col>
      <b-col class="d-none d-md-block" cols="2">{{ ticket.siteName }}</b-col>
      <b-col class="d-none d-md-block" cols="2">{{ ticket.techName }}</b-col>
      <b-col cols="1" md="auto">
        <b-icon :icon="isOpened ? 'chevron-up' : 'chevron-down'"></b-icon>
      </b-col>
    </b-row>
    <b-collapse
      :id="`accordion-${index}`"
      class="accordion-content px-2"
      role="tabpanel"
      @show="isOpened = true"
      @hide="isOpened = false"
    >
      <b-card-body class="px-2 py-2">
        <b-card-text v-html="detail"></b-card-text>
      </b-card-body>
    </b-collapse>
  </div>
</template>

<script>
import helpService from '@/services/helpService'
import moment from 'moment'
export default {
  props: {
    index: Number,
    currentPage: Number,
    ticket: Object,
  },
  data() {
    return {
      isOpened: false,
      detail: null,
      visible: false,
    }
  },
  methods: {
    closeAccordion() {
      if (this.isOpened)
        this.$root.$emit('bv::toggle::collapse', `accordion-${this.index}`)
    },
    async fetchDetail() {
      const res = await helpService.getTicketDetail(this.ticket.idIssue)
      this.detail = res.data.extended.note
    },
    statusTicketMap(ticketStatus) {
      switch (ticketStatus) {
        case 1:
          return {
            color: 'grey-color',
            label: 'Non traité',
            icon: 'clock-fill',
          }
        case 3:
          return {
            color: 'green-color',
            label: 'Résolu',
            icon: 'check-circle-fill',
          }
        case 2:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
        case 17:
        case 18:
        case 19:
        case 20:
        case 21:
        case 22:
        case 23:
        case 24:
          return {
            color: 'info-color',
            label: 'En cours',
            icon: 'arrow-repeat',
          }
        default:
          return {
            color: 'grey-color',
            label: 'Inconnu',
            icon: 'question-circle-fill',
          }
      }
    },
    formatDateFr(date) {
      //Already formated
      if (date && date.length === 10) return date
      else return moment(date).format('DD/MM/YYYY')
    },
  },
  computed: {
    currentPageComp() {
      return this.currentPage
    },
  },
  watch: {
    currentPageComp() {
      this.closeAccordion()
    },
    isOpened(val) {
      if (val) this.fetchDetail()
    },
  },
}
</script>

<style lang="scss" scoped>
.green-color {
  color: #51ed37;
}
.info-color {
  color: blue;
}
.grey-color {
  color: $h-main-text;
}
.accordion-header:hover {
  background: $h-hover-color;
}
</style>
