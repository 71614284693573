<template>
  <b-modal
    v-model="showNewTicketModal"
    hide-header
    hide-footer
    centered
    no-close-on-backdrop
    size="lg"
    v-if="showNewTicketModal"
    c
  >
    <div class="d-block text-center">
      <h4>
        Création d'un ticket
      </h4>
    </div>
    <div class="my-5 px-2">
      <h-input
        label="Titre"
        type="text"
        v-model.trim="title"
        class="mt-3"
      ></h-input>
      <h-select
        v-model="object"
        label="Objet"
        :options="['Objet 1', 'Objet 2', 'Objet 3']"
        class="mt-3"
      ></h-select>
      <h-textarea
        v-model="message"
        :label="'Votre message'"
        rows="4"
        class="mt-3"
      ></h-textarea>
    </div>
    <div class="d-flex mt-3">
      <h-button @click="$emit('close')" outline red class="ml-auto px-3 mr-3"
        >Annuler
      </h-button>
      <h-button class="px-3" blue>Envoyer</h-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    showNewTicketModal: Boolean,
  },
  data() {
    return {
      title: '',
      object: '',
      message: '',
    }
  },
  computed: {},
  methods: {},
}
</script>

<style lang="scss" scoped></style>
