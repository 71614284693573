<template>
  <section class="pt-4 pb-3" id="assistance">
    <div class="d-flex align-items-center mb-4">
      <h4 class="d-block">Tickets support</h4>
      <!-- <h-button
        @click="showNewTicketModal = true"
        class="ml-auto d-flex align-items-center"
        size="sm"
        blue
      >
        <b-icon icon="plus-circle-fill" class="mr-2" font-scale="0.9"></b-icon>
        <span>Créer un nouveau ticket</span>
      </h-button> -->
    </div>
    <h-card p0>
      <template v-if="ticketList && ticketList.total">
        <HelpAssistanceAccordion
          v-for="(ticket, index) in ticketList.result"
          :key="index"
          :index="index"
          :ticket="ticket"
          :currentPage="currentPage"
        />
      </template>
    </h-card>
    <b-pagination
      v-if="ticketList && ticketList.total > 11"
      class="mt-3"
      v-model="currentPage"
      pills
      per-page="10"
      :total-rows="ticketList.total"
      align="center"
    ></b-pagination>
    <ModalNewTicket
      :showNewTicketModal="showNewTicketModal"
      @close="showNewTicketModal = false"
    />
  </section>
</template>

<script>
import helpService from '@/services/helpService'
import HelpAssistanceAccordion from '@/components/help/HelpAssistanceAccordion'
import ModalNewTicket from '@/components/modals/ModalNewTicket'

import { mapState } from 'vuex'
export default {
  components: {
    HelpAssistanceAccordion,
    ModalNewTicket,
  },
  data() {
    return {
      showNewTicketModal: false,
      ticketList: null,
      currentPage: 1,
    }
  },
  watch: {
    currentPage() {
      this.fetchTicket()
    },
  },
  methods: {
    async fetchTicket() {
      const visibleSitesIds = this.user.visibleSites.map((e) => e.id)
      const params = {
        IdBrand: this.user.visibleBrands[0].id,
        IdSites: visibleSitesIds,
        pageIndex: this.currentPage,
        pageLength: 10,
      }
      const res = await helpService.getTicketList(params)
      this.ticketList = res.data
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  mounted() {
    this.fetchTicket()
  },
}
</script>

<style lang="scss" scoped></style>
