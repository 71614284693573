<template>
  <a :href="linkTo" v-smooth-scroll>
    <h-card p0 hover class="h-100 card-wrapper">
      <div class="help-card px-3 py-2 h-100">
        <b-row>
          <b-col
            lg="4"
            md="2"
            sm="3"
            cols="3"
            class="d-flex align-items-center justify-content-center illustration "
          >
            <slot name="illustration"></slot>
          </b-col>
          <b-col
            lg="8"
            md="10"
            sm="9"
            cols="9"
            class="d-flex flex-column cursor-pointer"
          >
            <h5 class="font-weight-bold mb-2">
              <slot name="title-help"></slot>
            </h5>
            <p class="text-help-card">
              <slot></slot>
            </p>
          </b-col>
        </b-row>
      </div>
    </h-card>
  </a>
</template>

<script>
export default {
  props: {
    linkTo: String,
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
}
.card-wrapper {
  transition: 0.3s;
  border-radius: 0.25rem;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 7px -2px #d4d4d4;
    .help-card::after {
      opacity: 1;
    }
  }
}

.help-card {
  transition: 0.3s;
  cursor: pointer;
  background: white;
  color: $h-main-text;
  border-radius: 0.25rem;
  position: relative;
  z-index: 2;
  &::after {
    border-radius: 0.25rem;
    transition: 0.3s;
    content: '';
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        rgba(240, 60, 31, 0.8) 0%,
        rgba(240, 60, 31, 0) 60%
      )
      white;
  }
  .text-help-card {
    color: $h-main-text-light;
  }
}
</style>
